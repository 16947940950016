import { DEPLOYMENT_TYPE } from '../../Rollup/RollupConst';
import {
  ROLLUP_ARBITRUM_DEPLOYMENT_BRIDGE_DOCS_URL,
  ROLLUP_FRAXTAL_DEPLOYMENT_BRIDGE_DOCS_URL,
  ROLLUP_OPTIMISM_DEPLOYMENT_BRIDGE_DOCS_URL,
} from '../const/values';

export function getBridgeDocsUrl(deploymentType: DEPLOYMENT_TYPE | undefined) {
  switch (deploymentType) {
    case DEPLOYMENT_TYPE.optimism:
      return ROLLUP_OPTIMISM_DEPLOYMENT_BRIDGE_DOCS_URL;
    case DEPLOYMENT_TYPE.fraxtal:
      return ROLLUP_FRAXTAL_DEPLOYMENT_BRIDGE_DOCS_URL;
    default:
      return ROLLUP_ARBITRUM_DEPLOYMENT_BRIDGE_DOCS_URL;
  }
}
