import React, { JSX } from 'react';
import { AppModal } from '@ankr.com/raas-ui';
import { Box, Button, Typography } from '@mui/material';

import { useTranslation } from '../../../../../i18n';
import { DialogId } from '../../../../actions/openDialog';
import { USER_SUPPORT_URL } from '../../../../const/values';
import { useDialog } from '../../../../hooks/useDialog';
import { useGetAccountInfoQuery } from '../../actions/getAccountInfo';
import { accountDeleteModalTranslation } from './translation';
import { useAccountDeleteModalStyles } from './useAccountDeleteModalStyles';

export function AccountDeleteModal(): JSX.Element {
  const { classes } = useAccountDeleteModalStyles();

  const { keys, t } = useTranslation(accountDeleteModalTranslation);

  const { data: accountInfoData } = useGetAccountInfoQuery();

  const supportUrlParams = new URLSearchParams();
  if (accountInfoData?.email) {
    supportUrlParams.set('email', accountInfoData.email);
    supportUrlParams.set('summary', `Delete account ${accountInfoData.email}`);
  }

  const {
    open: accountDeleteModalOpen,
    handleClose: handleAccountDeleteModalClose,
  } = useDialog(DialogId.AccountDelete);

  return (
    <AppModal
      open={accountDeleteModalOpen}
      onClose={handleAccountDeleteModalClose}
      title={t(keys.title)}
      maxWidth="xs"
      titleCentered
      withoutCloseButton
      titleClassName={classes.modalTitle}
      classes={{ paper: classes.modalContent }}
    >
      <Typography variant="body2" className={classes.modalHint}>
        {t(keys.hint)}
      </Typography>
      <Box className={classes.modalButtons}>
        <Button
          size="large"
          href={`${USER_SUPPORT_URL}?${supportUrlParams.toString()}`}
          rel="noreferrer"
          target="_blank"
        >
          {t(keys.contact)}
        </Button>
        <Button
          onClick={handleAccountDeleteModalClose}
          size="large"
          variant="outlined"
          fullWidth
        >
          {t(keys.close)}
        </Button>
      </Box>
    </AppModal>
  );
}
