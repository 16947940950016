import React, { JSX, useMemo } from 'react';
import { useIsInPast } from '@ankr.com/raas-hooks';
import {
  CopyButton,
  QueryEmpty,
  QueryLoadingAbsolute,
} from '@ankr.com/raas-ui';
import { tCommon } from '@ankr.com/raas-utils';
import { Check, ExternalLink } from '@ankr.com/ui';
import {
  Alert,
  Box,
  Button,
  Card,
  Container,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { useQueryParam } from 'use-query-params';

import { DialogId } from '../../../common/actions/openDialog';
import { CountDownString } from '../../../common/components/CountDownString';
import { PaymentStatusModals } from '../../../common/components/PaymentStatusModals';
import { StackDALIcon } from '../../../common/components/StackDALIcon';
import { ROLLUP_POLLING_INTERVAL } from '../../../common/const/values';
import { useDialog } from '../../../common/hooks/useDialog';
import { getChain } from '../../../common/utils/getChain';
import { getToken } from '../../../common/utils/getToken';
import { DashboardRoutesConfig } from '../../../Dashboard/DashboardRoutes';
import { useTranslation } from '../../../i18n';
import { useSetBreadcrumbs } from '../../../layout/components/Breadcrumbs';
import { ROLLUP_STATUS, SEQUENCER_MANAGEMENT_TYPE } from '../../RollupConst';
import { useGetRollupQuery } from './actions/getRollup';
import { RollupBridgeModal } from './components/RollupBridgeModal';
import { RollupDepositModal } from './components/RollupDepositModal';
import { RollupHeader } from './components/RollupHeader';
import { RollupRPCEndpointsModal } from './components/RollupRPCEndpointsModal';
import { rollupTranslation } from './translation';
import { useRollupStyles } from './useRollupStyles';

export function Rollup(): JSX.Element {
  const { classes, cx } = useRollupStyles();

  const { keys, t } = useTranslation(rollupTranslation);

  const [uuid] = useQueryParam<string>('uuid');

  const {
    data: rollupData,
    isLoading: isRollupLoading,
    isSuccess: isRollupSuccess,
    isError: isRollupError,
  } = useGetRollupQuery(
    { uuid },
    {
      pollingInterval: ROLLUP_POLLING_INTERVAL,
      skipPollingIfUnfocused: true,
      skip: !uuid,
    },
  );

  const breadcrumbs = useMemo(
    () => [
      {
        title: tCommon('navigation.dashboard'),
        link: DashboardRoutesConfig.Dashboard.generatePath(),
      },
      {
        title: rollupData?.networkName ?? tCommon('common.empty-placeholder'),
      },
    ],
    [rollupData?.networkName],
  );
  useSetBreadcrumbs(breadcrumbs);

  const { handleOpen: handleRollupDepositModalOpen } = useDialog(
    DialogId.RollupDeposit,
  );

  const isRollupEmpty = useMemo(
    () => (isRollupSuccess || isRollupError) && !rollupData,
    [isRollupError, isRollupSuccess, rollupData],
  );

  const isExpired = useIsInPast({ endDate: rollupData?.paidUntil });

  const { handleOpen: handleRPCEndpointsModalOpen } = useDialog(
    DialogId.RollupRPCEndpoints,
  );

  const { handleOpen: handleRollupBridgeModalOpen } = useDialog(
    DialogId.RollupBridge,
  );

  const { imageSrc: gasTokenLogo, name: gasTokenName } = getToken({
    value: rollupData?.gasToken,
  });

  const { imageSrc: l1ChainLogo, name: l1ChainName } = getChain({
    value: rollupData?.l1Chain,
  });

  const RPCAmount = useMemo(() => {
    return rollupData?.plan?.settings?.find(
      setting => setting.key === 'rpc_amount',
    )?.value;
  }, [rollupData?.plan?.settings]);

  if (isRollupEmpty) {
    return (
      <Container className={classes.root}>
        <QueryEmpty />
      </Container>
    );
  }

  return (
    <>
      <Container className={classes.root}>
        {isRollupLoading ? (
          <QueryLoadingAbsolute />
        ) : (
          <Box className={classes.contentWrap}>
            <RollupHeader rollupData={rollupData} isExpired={isExpired} />

            {rollupData?.status === ROLLUP_STATUS.pending_deposit && (
              <>
                <Alert
                  severity="error"
                  classes={{
                    root: classes.depositAlert,
                    message: classes.depositAlertMessage,
                  }}
                >
                  {t(keys.rollup.pendingDepositAlert, {
                    amount:
                      rollupData?.deposits?.parsedForUI.amountsWithToken?.join(
                        tCommon('common.plus-sign-spaced'),
                      ),
                  })}
                  <Button
                    variant="text"
                    color="primary"
                    size="extraSmall"
                    className={classes.depositButton}
                    onClick={handleRollupDepositModalOpen}
                  >
                    {t(keys.rollup.depositButton, {
                      amount:
                        rollupData?.deposits?.parsedForUI.amountsWithToken?.join(
                          tCommon('common.plus-sign-spaced'),
                        ),
                    })}
                  </Button>
                </Alert>
                <RollupDepositModal
                  deposits={rollupData?.deposits}
                  l1ChainTokenLogo={l1ChainLogo}
                  l1ChainTokenName={l1ChainName}
                />
              </>
            )}

            <Grid container spacing={5}>
              <Grid item xs={12} sm={6} lg={4}>
                <Card className={classes.card}>
                  <Box display="flex" flexGrow={1}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap={2}
                      flexGrow={1}
                      justifyContent="space-between"
                    >
                      <Box className={classes.listRow} mb={9}>
                        <Box className={classes.listRowLeft}>
                          <Typography
                            variant="button1"
                            className={classes.infoTitle}
                          >
                            {t(keys.rollup.RPCNodes)}
                          </Typography>
                        </Box>
                        <Box className={classes.listRowRight} />
                      </Box>

                      {!!RPCAmount && (
                        <Box>
                          <Typography variant="h5">
                            {t(keys.rollup.nodesAmount, {
                              number: RPCAmount,
                            })}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Card>
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <Card className={classes.card}>
                  <Box className={classes.listRow} mb={9}>
                    <Box className={classes.listRowLeft}>
                      <Typography
                        variant="button1"
                        className={classes.infoTitle}
                      >
                        {t(keys.rollup.generalInfo)}
                      </Typography>
                    </Box>
                  </Box>

                  <Box className={classes.listBody}>
                    <Box className={classes.listRow}>
                      <Box className={classes.listRowLeft}>
                        <Typography
                          variant="button3"
                          className={classes.infoTitle}
                        >
                          {t(keys.rollup.chainId)}
                        </Typography>
                      </Box>
                      <Box className={classes.listRowRight}>
                        <Typography variant="button3">
                          {rollupData?.chainId}
                        </Typography>
                      </Box>
                    </Box>

                    {!!l1ChainLogo && !!l1ChainName && (
                      <Box className={classes.listRow}>
                        <Box className={classes.listRowLeft}>
                          <Typography
                            variant="button3"
                            className={classes.infoTitle}
                          >
                            {t(keys.rollup.l1Chain)}
                          </Typography>
                        </Box>
                        <Box className={classes.listRowRight}>
                          <Typography variant="button3">
                            <Box display="flex" gap={2} alignItems="center">
                              <img
                                src={l1ChainLogo}
                                alt={l1ChainName}
                                className={classes.blockchainLogo}
                              />
                              {l1ChainName}
                            </Box>
                          </Typography>
                        </Box>
                      </Box>
                    )}

                    {!isExpired && !!rollupData?.paidUntil && (
                      <Box className={classes.listRow}>
                        <Box className={classes.listRowLeft}>
                          <Typography
                            variant="button3"
                            className={classes.infoTitle}
                          >
                            {t(keys.rollup.duration)}
                          </Typography>
                        </Box>
                        <Box className={classes.listRowRight}>
                          <Typography
                            variant="button3"
                            sx={{ fontFeatureSettings: '"tnum" 1' }}
                          >
                            <CountDownString date={rollupData.paidUntil} />
                          </Typography>
                        </Box>
                      </Box>
                    )}

                    <Box className={classes.listRow}>
                      <Box className={classes.listRowLeft}>
                        <Typography
                          variant="button3"
                          className={classes.infoTitle}
                        >
                          {t(keys.rollup.creationDate)}
                        </Typography>
                      </Box>
                      <Box className={classes.listRowRight}>
                        <Typography variant="button3">
                          {tCommon('datetime.medium-short', {
                            value: rollupData?.createdAt,
                          })}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Card>
              </Grid>

              <Grid item xs={12} lg={4} display="flex">
                <Grid container columnSpacing={2} rowSpacing={2.5} flexGrow={1}>
                  <Grid item xs={12} md={6} lg={12}>
                    <Card
                      className={cx(
                        classes.cardSmall,
                        !rollupData?.details?.blockHeight &&
                          classes.cardDisabled,
                      )}
                    >
                      <Box className={classes.listRow}>
                        <Box className={classes.listRowLeft}>
                          <Typography
                            variant="subtitle3"
                            className={classes.infoTitle}
                          >
                            {t(keys.rollup.blockHeight)}
                          </Typography>
                        </Box>
                        <Box className={classes.listRowRight}>
                          <Typography variant="button3">
                            {rollupData?.details?.blockHeight ||
                              tCommon('common.empty-placeholder')}
                          </Typography>
                        </Box>
                      </Box>
                    </Card>
                  </Grid>

                  <Grid item xs={12} md={6} lg={12}>
                    <Card
                      className={cx(
                        classes.cardSmall,
                        !rollupData?.details?.rpcEndpoints &&
                          classes.cardDisabled,
                      )}
                    >
                      <Box className={classes.listRow}>
                        <Box className={classes.listRowLeft}>
                          <Typography
                            variant="subtitle3"
                            className={classes.infoTitle}
                            whiteSpace="nowrap"
                            component={Box}
                          >
                            {t(
                              !!rollupData?.details?.rpcEndpoints &&
                                rollupData.details.rpcEndpoints.length > 1
                                ? keys.rollup.RPCEndpoints
                                : keys.rollup.RPCEndpoint,
                            )}
                          </Typography>
                        </Box>
                        <Box className={classes.listRowRight} overflow="hidden">
                          {!rollupData?.details?.rpcEndpoints && (
                            <Typography variant="button3">
                              {tCommon('common.empty-placeholder')}
                            </Typography>
                          )}

                          {!!rollupData?.details?.rpcEndpoints &&
                            rollupData.details.rpcEndpoints.length === 1 && (
                              <Box display="flex" gap={1} alignItems="center">
                                <Tooltip
                                  title={rollupData.details.rpcEndpoints[0]}
                                >
                                  <Typography
                                    variant="button3"
                                    component={Box}
                                    whiteSpace="nowrap"
                                    textOverflow="ellipsis"
                                    overflow="hidden"
                                  >
                                    {rollupData.details.rpcEndpoints[0]}
                                  </Typography>
                                </Tooltip>
                                <CopyButton
                                  text={rollupData.details.rpcEndpoints[0]}
                                  size="extraSmall"
                                  className={classes.smallFlatBtn}
                                />
                              </Box>
                            )}

                          {!!rollupData?.details?.rpcEndpoints &&
                            rollupData.details.rpcEndpoints.length > 1 && (
                              <Box display="flex" gap={1} alignItems="center">
                                <Button
                                  size="extraSmall"
                                  color="primary"
                                  variant="outlined"
                                  onClick={handleRPCEndpointsModalOpen}
                                  className={classes.smallFlatBtn}
                                >
                                  {t(keys.rollup.showAll)}
                                </Button>
                                <RollupRPCEndpointsModal
                                  RPCEndpoints={rollupData.details.rpcEndpoints}
                                />
                              </Box>
                            )}
                        </Box>
                      </Box>
                    </Card>
                  </Grid>

                  {rollupData?.sequencerManagementType ===
                  SEQUENCER_MANAGEMENT_TYPE.self ? (
                    <>
                      {!!rollupData?.sequencerUrl && (
                        <Grid item xs={6}>
                          <Card className={classes.cardSmall}>
                            <Box className={classes.listRow}>
                              <Box className={classes.listRowLeft}>
                                <Typography
                                  variant="subtitle3"
                                  className={classes.infoTitle}
                                >
                                  {t(keys.rollup.sequencerURL)}
                                </Typography>
                              </Box>
                              <Box className={classes.listRowRight}>
                                <CopyButton
                                  text={rollupData.sequencerUrl}
                                  size="extraSmall"
                                  className={classes.smallFlatBtn}
                                />
                              </Box>
                            </Box>
                          </Card>
                        </Grid>
                      )}
                      {!!rollupData?.sequencerAddress && (
                        <Grid item xs={6}>
                          <Card className={classes.cardSmall}>
                            <Box className={classes.listRow}>
                              <Box className={classes.listRowLeft}>
                                <Typography
                                  variant="subtitle3"
                                  className={classes.infoTitle}
                                >
                                  {t(keys.rollup.sequencerAddress)}
                                </Typography>
                              </Box>
                              <Box className={classes.listRowRight}>
                                <CopyButton
                                  text={rollupData.sequencerAddress}
                                  size="extraSmall"
                                  className={classes.smallFlatBtn}
                                />
                              </Box>
                            </Box>
                          </Card>
                        </Grid>
                      )}
                    </>
                  ) : (
                    <Grid item xs={12}>
                      <Card className={classes.cardSmall}>
                        <Box className={classes.listRow}>
                          <Box className={classes.listRowLeft}>
                            <Typography
                              variant="subtitle3"
                              className={classes.infoTitle}
                            >
                              {t(keys.rollup.sequencer)}
                            </Typography>
                          </Box>
                          <Box className={classes.listRowRight}>
                            <Typography variant="button3">
                              {t(keys.rollup.ankrManages)}
                            </Typography>
                          </Box>
                        </Box>
                      </Card>
                    </Grid>
                  )}

                  <Grid item xs={6}>
                    <Card
                      className={cx(
                        classes.cardSmall,
                        !rollupData?.details?.bridge && classes.cardDisabled,
                      )}
                    >
                      <Box className={classes.listRow}>
                        <Box className={classes.listRowLeft}>
                          <Typography
                            variant="subtitle3"
                            className={classes.infoTitle}
                          >
                            {t(keys.rollup.bridge)}
                          </Typography>
                        </Box>
                        {rollupData?.details?.bridge ? (
                          <Box className={classes.listRowRight}>
                            <Box display="flex" gap={1} alignItems="center">
                              <Button
                                size="extraSmall"
                                color="primary"
                                variant="outlined"
                                onClick={handleRollupBridgeModalOpen}
                                className={classes.smallFlatBtn}
                              >
                                {t(keys.rollup.showInfo)}
                              </Button>
                              <RollupBridgeModal
                                bridgeDetails={rollupData.details.bridge}
                                deploymentType={rollupData.deploymentType}
                              />
                            </Box>
                          </Box>
                        ) : (
                          <Typography variant="button3">
                            {tCommon('common.empty-placeholder')}
                          </Typography>
                        )}
                      </Box>
                    </Card>
                  </Grid>

                  <Grid item xs={6}>
                    <Card
                      className={cx(
                        classes.cardSmall,
                        !rollupData?.details?.explorerUrl &&
                          classes.cardDisabled,
                      )}
                    >
                      <Box className={classes.listRow}>
                        <Box className={classes.listRowLeft}>
                          <Typography
                            variant="subtitle3"
                            className={classes.infoTitle}
                          >
                            {t(keys.rollup.explorer)}
                          </Typography>
                        </Box>
                        {rollupData?.details?.explorerUrl ? (
                          <Box className={classes.listRowRight}>
                            <IconButton
                              size="extraSmall"
                              color="primary"
                              href={rollupData.details.explorerUrl}
                              className={classes.smallFlatBtn}
                              rel="noreferrer"
                              target="_blank"
                            >
                              <ExternalLink sx={{ color: 'inherit' }} />
                            </IconButton>
                          </Box>
                        ) : (
                          <Typography variant="button3">
                            {tCommon('common.empty-placeholder')}
                          </Typography>
                        )}
                      </Box>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} lg={8}>
                <Card className={classes.card}>
                  <Box className={classes.listRow} mb={9}>
                    <Box className={classes.listRowLeft}>
                      <Typography
                        variant="button1"
                        className={classes.infoTitle}
                      >
                        {t(keys.rollup.package)}
                      </Typography>
                    </Box>
                  </Box>

                  <Grid
                    container
                    columnSpacing={5}
                    className={classes.packageList}
                  >
                    {rollupData?.plan?.settings?.map(setting => {
                      const value =
                        typeof setting.value === 'boolean' ? (
                          <Check color="success" />
                        ) : (
                          setting.value
                        );

                      return (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          className={classes.packageItem}
                          key={`setting_${setting.uuid}`}
                        >
                          <Box className={classes.listRow}>
                            <Box className={classes.listRowLeft}>
                              <Typography
                                variant="button3"
                                className={classes.infoTitle}
                              >
                                {setting.title}
                              </Typography>
                            </Box>
                            <Box className={classes.listRowRight}>
                              <Typography
                                variant="button3"
                                minWidth={24}
                                textAlign="center"
                                component={Box}
                              >
                                {value}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Card>
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <Card className={classes.card}>
                  <Box className={classes.listRow} mb={9}>
                    <Box className={classes.listRowLeft}>
                      <Typography
                        variant="button1"
                        className={classes.infoTitle}
                      >
                        {t(keys.rollup.details)}
                      </Typography>
                    </Box>
                  </Box>

                  <Box className={classes.listBody}>
                    {!!rollupData?.dataAvailabilityLayer?.key &&
                      !!rollupData?.dataAvailabilityLayer?.title && (
                        <Box className={classes.listRow}>
                          <Box className={classes.listRowLeft}>
                            <Typography
                              variant="button3"
                              className={classes.infoTitle}
                            >
                              {t(keys.rollup.DAL)}
                            </Typography>
                          </Box>
                          <Box className={classes.listRowRight}>
                            <Typography variant="button3">
                              <Box display="flex" gap={2} alignItems="center">
                                <StackDALIcon
                                  keyString={
                                    rollupData.dataAvailabilityLayer.key
                                  }
                                  title={rollupData.dataAvailabilityLayer.title}
                                  className={classes.blockchainLogo}
                                  isDAL
                                />
                                {rollupData.dataAvailabilityLayer.title}
                              </Box>
                            </Typography>
                          </Box>
                        </Box>
                      )}
                    {!!gasTokenLogo && !!gasTokenName && (
                      <Box className={classes.listRow}>
                        <Box className={classes.listRowLeft}>
                          <Typography
                            variant="button3"
                            className={classes.infoTitle}
                          >
                            {t(keys.rollup.gasToken)}
                          </Typography>
                        </Box>
                        <Box className={classes.listRowRight}>
                          <Typography variant="button3">
                            <Box display="flex" gap={2} alignItems="center">
                              <img
                                src={gasTokenLogo}
                                alt={gasTokenName}
                                className={classes.blockchainLogo}
                              />
                              {gasTokenName}
                            </Box>
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </Box>
        )}
      </Container>

      <PaymentStatusModals
        planPrice={rollupData?.plan?.price}
        uuid={rollupData?.uuid}
        status={rollupData?.status}
        grade={rollupData?.plan.grade}
        deposits={rollupData?.deposits}
      />
    </>
  );
}
