import React, { JSX, useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { CardDialog, QueryLoadingCentered } from '@ankr.com/raas-ui';
import {
  isValidWebsiteUrl,
  isValidXUsername,
  tCommon,
} from '@ankr.com/raas-utils';
import { Delete, LoadingButton, TextField } from '@ankr.com/ui';
import { Box, Button, Container } from '@mui/material';

import { useTranslation } from '../../../i18n';
import { DialogId } from '../../actions/openDialog';
import { useDialog } from '../../hooks/useDialog';
import { useGetAccountInfoQuery } from './actions/getAccountInfo';
import { usePostAccountInfoMutation } from './actions/postAccountInfo';
import { IAccountInfo } from './actions/types';
import { AccountDeleteModal } from './components/AccountDeleteModal';
import { accountInfoFormTranslation } from './translation';

interface IAccountInfoFormProps {
  showSubtitle?: boolean;
}

export function AccountInfoForm({
  showSubtitle,
}: IAccountInfoFormProps): JSX.Element {
  const { keys, t } = useTranslation(accountInfoFormTranslation);

  const [postAccountInfo, { isLoading: isAccountInfoPosting }] =
    usePostAccountInfoMutation();

  const {
    data: accountInfoData,
    isLoading: isAccountInfoLoading,
    isSuccess: isAccountInfoSuccess,
  } = useGetAccountInfoQuery();

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    setValue,
  } = useForm<IAccountInfo>({
    defaultValues: {
      email: '',
      companyName: '',
      companyInfo: '',
      companyTwitter: '',
    },
  });

  useEffect(() => {
    if (isAccountInfoSuccess) {
      if (accountInfoData?.email) {
        setValue('email', accountInfoData?.email, { shouldDirty: false });
      }
      if (accountInfoData?.companyName) {
        setValue('companyName', accountInfoData?.companyName, {
          shouldDirty: false,
        });
      }
      if (accountInfoData?.companyInfo) {
        setValue('companyInfo', accountInfoData?.companyInfo, {
          shouldDirty: false,
        });
      }
      if (accountInfoData?.companyTwitter) {
        setValue('companyTwitter', accountInfoData?.companyTwitter, {
          shouldDirty: false,
        });
      }
    }
  }, [
    accountInfoData?.companyInfo,
    accountInfoData?.companyName,
    accountInfoData?.companyTwitter,
    accountInfoData?.email,
    isAccountInfoSuccess,
    setValue,
  ]);

  const onSubmit = useCallback(
    async (payload: IAccountInfo) => {
      await postAccountInfo(payload);
    },
    [postAccountInfo],
  );

  const xInputValidation = useCallback((value: string | undefined) => {
    if (value) {
      if (isValidWebsiteUrl(value) || isValidXUsername(value)) {
        return true;
      }
      if (!isValidXUsername(value)) {
        return tCommon('validation.invalid-username');
      }
      if (!isValidWebsiteUrl(value)) {
        return tCommon('validation.invalid-url');
      }
    }
  }, []);

  const { handleOpen: handleAccountDeleteModalOpen } = useDialog(
    DialogId.AccountDelete,
  );

  return (
    <Container>
      <CardDialog
        title={t(keys.title)}
        subtitle={showSubtitle ? t(keys.subtitle) : ''}
        hideCloseButton
      >
        {isAccountInfoLoading ? (
          <QueryLoadingCentered />
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box mb={7}>
              <Controller
                name="email"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    helperText={error ? error.message : t(keys.emailHint)}
                    error={!!error}
                    onChange={onChange}
                    value={value}
                    fullWidth
                    type="email"
                    label={t(keys.email)}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                )}
              />
            </Box>
            <Box mb={7}>
              <Controller
                name="companyName"
                rules={{
                  required: tCommon('validation.required'),
                }}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    helperText={error ? error.message : null}
                    error={!!error}
                    onChange={onChange}
                    value={value}
                    fullWidth
                    label={t(keys.companyName)}
                  />
                )}
              />
            </Box>
            <Box mb={7}>
              <Controller
                name="companyInfo"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    helperText={error ? error.message : t(keys.companyInfoHint)}
                    error={!!error}
                    onChange={onChange}
                    value={value}
                    fullWidth
                    multiline
                    minRows={1}
                    maxRows={5}
                    label={t(keys.companyInfo)}
                  />
                )}
              />
            </Box>
            <Box mb={10}>
              <Controller
                name="companyTwitter"
                rules={{
                  validate: v => xInputValidation(v),
                }}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    helperText={error ? error.message : t(keys.twitterHint)}
                    error={!!error}
                    onChange={onChange}
                    value={value}
                    fullWidth
                    type="text"
                    label={t(keys.companyTwitter)}
                  />
                )}
              />
            </Box>

            <Box display="flex" flexDirection="column" gap={3}>
              <LoadingButton
                loading={isAccountInfoPosting}
                disabled={!isDirty}
                type="submit"
                size="large"
                fullWidth
              >
                {t(keys.submitBtn)}
              </LoadingButton>

              <Button
                onClick={handleAccountDeleteModalOpen}
                color="secondary"
                size="large"
                fullWidth
                startIcon={<Delete />}
              >
                {t(keys.deleteAccount)}
              </Button>
              <AccountDeleteModal />
            </Box>
          </form>
        )}
      </CardDialog>
    </Container>
  );
}
