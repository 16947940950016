import { queryFnNotifyWrapper } from '@ankr.com/raas-ui';

import { cacheTags, Gateways, mainApi } from '../../../../api';
import { mapDeploymentFromApi } from '../../../../common/utils/mapDeploymentFromApi';
import { IRollupDeployed, IRollupDeployedApi } from '../../../RollupConst';

interface IGetRollupProps {
  uuid?: string;
}

export const { useGetRollupQuery } = mainApi.injectEndpoints({
  endpoints: build => ({
    getRollup: build.query<IRollupDeployed, IGetRollupProps>({
      queryFn: queryFnNotifyWrapper<IGetRollupProps, never, IRollupDeployed>(
        async ({ uuid }) => {
          if (!uuid) {
            throw new Error('UUID is not available, please try again.');
          }

          const { data }: { data: IRollupDeployedApi } =
            await Gateways.auth.api.get(`/api/v1/deployments/${uuid}`);

          const mappedData: IRollupDeployed = mapDeploymentFromApi({
            deployment: data,
          });

          return { data: mappedData };
        },
      ),
      providesTags: [cacheTags.rollups],
    }),
  }),
});
