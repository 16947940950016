import { queryFnNotifyWrapper } from '@ankr.com/raas-ui';

import { cacheTags, Gateways, mainApi } from '../../../../api';
import { IDeployRollupResponseApi } from '../../../RollupConst';

interface IExtendRollupProps {
  uuid?: string;
}

export const { useExtendRollupMutation } = mainApi.injectEndpoints({
  endpoints: build => ({
    extendRollup: build.mutation<IDeployRollupResponseApi, IExtendRollupProps>({
      queryFn: queryFnNotifyWrapper<
        IExtendRollupProps,
        never,
        IDeployRollupResponseApi
      >(async ({ uuid }) => {
        if (!uuid) {
          throw new Error('UUID is not available, please try again.');
        }

        const res = await Gateways.auth.api.post(
          `/api/v1/deployments/${uuid}/extend`,
        );

        return { data: res.data };
      }),
      invalidatesTags: [cacheTags.rollups],
    }),
  }),
});
