import React, { JSX, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppModal, ImageX } from '@ankr.com/raas-ui';
import { tCommon } from '@ankr.com/raas-utils';
import { Close, LoadingButton } from '@ankr.com/ui';
import { Box, Button, IconButton, Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import { useQueryParam } from 'use-query-params';

import { DashboardRoutesConfig } from '../../../Dashboard/DashboardRoutes';
import { useLocaleMemo, useTranslation } from '../../../i18n';
import {
  GRADE,
  IDepositItem,
  ROLLUP_STATUS,
} from '../../../Rollup/RollupConst';
import { useExtendRollupMutation } from '../../../Rollup/screens/Rollup/actions/extendRollup';
import { DialogId } from '../../actions/openDialog';
import imgUrl from '../../assets/success.png';
import imgUrl2x from '../../assets/success@2x.png';
import { useDialog } from '../../hooks/useDialog';
import { paymentStatusTranslation } from './translation';
import { usePaymentStatusModalsStyles } from './usePaymentStatusModalsStyles';

interface IPaymentStatusModalsProps {
  uuid?: string;
  planPrice?: BigNumber;
  status?: ROLLUP_STATUS;
  grade?: GRADE;
  deposits?: IDepositItem;
}

export function PaymentStatusModals({
  uuid,
  planPrice,
  status,
  grade,
  deposits,
}: IPaymentStatusModalsProps): JSX.Element {
  const { classes } = usePaymentStatusModalsStyles();

  const { keys, t } = useTranslation(paymentStatusTranslation);

  const [success, setSuccess] = useQueryParam<
    boolean | 'true' | 'false' | undefined
  >('success');
  const [failed, setFailed] = useQueryParam<
    boolean | 'true' | 'false' | undefined
  >('failed');

  const {
    open: successModalOpen,
    handleClose: handleSuccessModalClose,
    handleOpen: handleSuccessModalOpen,
  } = useDialog(DialogId.BillingSuccess);
  const {
    open: failedModalOpen,
    handleClose: handleFailedModalClose,
    handleOpen: handleFailedModalOpen,
  } = useDialog(DialogId.BillingFailed);

  useEffect(() => {
    if (success && success === 'true') {
      handleSuccessModalOpen();
      setSuccess(undefined, 'replaceIn');
    }
  }, [handleSuccessModalOpen, setSuccess, success]);
  useEffect(() => {
    if (failed && failed === 'true') {
      handleFailedModalOpen();
      setFailed(undefined, 'replaceIn');
    }
  }, [failed, handleFailedModalOpen, setFailed]);

  const { handleOpen: handleRollupDepositModalOpen } = useDialog(
    DialogId.RollupDeposit,
  );

  const onDepositModalClick = useCallback(() => {
    handleSuccessModalClose();
    handleRollupDepositModalOpen();
  }, [handleRollupDepositModalOpen, handleSuccessModalClose]);

  const navigate = useNavigate();

  const [
    extendRollup,
    {
      data: extendRollupResult,
      isLoading: isExtendRollupLoading,
      isSuccess: isExtendRollupSuccess,
    },
  ] = useExtendRollupMutation();

  useEffect(() => {
    if (isExtendRollupSuccess) {
      if (extendRollupResult?.subscription?.url) {
        handleFailedModalClose();
        window.location.assign(extendRollupResult.subscription.url);
      } else {
        handleFailedModalClose();
        navigate(DashboardRoutesConfig.Dashboard.generatePath());
      }
    }
  }, [
    extendRollupResult?.subscription?.url,
    handleFailedModalClose,
    isExtendRollupSuccess,
    navigate,
  ]);

  const onSubmitClick = useCallback(async () => {
    await extendRollup({ uuid });
  }, [extendRollup, uuid]);

  const successModalHint = useLocaleMemo(() => {
    if (status === ROLLUP_STATUS.initializing) {
      if (planPrice && !planPrice.isZero()) {
        return t(keys.successModal.paidInitializingHint, {}, true);
      }
      return t(keys.successModal.freeInitializingHint, {}, true);
    }

    if (status === ROLLUP_STATUS.pending_deposit) {
      if (grade === GRADE.mainnet) {
        return t(
          keys.successModal.successPendingDepositMainnetHint,
          {
            amount: deposits?.parsedForUI.amountsWithToken?.join(
              tCommon('common.plus-sign-spaced'),
            ),
          },
          true,
        );
      }
      if (planPrice && !planPrice.isZero()) {
        return t(
          keys.successModal.successPendingDepositPaidTestnetHint,
          {
            amount: deposits?.parsedForUI.amountsWithToken?.join(
              tCommon('common.plus-sign-spaced'),
            ),
          },
          true,
        );
      }
      return t(
        keys.successModal.successPendingDepositFreeTestnetHint,
        {
          amount: deposits?.parsedForUI.amountsWithToken?.join(
            tCommon('common.plus-sign-spaced'),
          ),
        },
        true,
      );
    }
    if (
      status !== ROLLUP_STATUS.initializing &&
      status !== ROLLUP_STATUS.pending_deposit
    ) {
      return t(keys.successModal.successInitializedHint, {}, true);
    }
    return t(keys.successModal.successHint, {}, true);
  }, [
    deposits?.parsedForUI.amountsWithToken,
    grade,
    keys.successModal.freeInitializingHint,
    keys.successModal.paidInitializingHint,
    keys.successModal.successHint,
    keys.successModal.successInitializedHint,
    keys.successModal.successPendingDepositFreeTestnetHint,
    keys.successModal.successPendingDepositMainnetHint,
    keys.successModal.successPendingDepositPaidTestnetHint,
    planPrice,
    status,
    t,
  ]);

  const successModalButton = useLocaleMemo(() => {
    if (status === ROLLUP_STATUS.initializing) {
      return (
        <LoadingButton
          loading
          size="large"
          fullWidth
          className={classes.modalBtn}
        >
          {t(keys.successModal.initializing)}
        </LoadingButton>
      );
    }
    if (status === ROLLUP_STATUS.pending_deposit) {
      return (
        <Button
          onClick={onDepositModalClick}
          size="large"
          fullWidth
          className={classes.modalBtn}
        >
          {t(keys.successModal.deposit, {
            amount: deposits?.parsedForUI.amountsWithToken?.join(
              tCommon('common.plus-sign-spaced'),
            ),
          })}
        </Button>
      );
    }
    return (
      <Button
        onClick={handleSuccessModalClose}
        size="large"
        fullWidth
        className={classes.modalBtn}
      >
        {t(keys.successModal.button)}
      </Button>
    );
  }, [
    classes.modalBtn,
    deposits?.parsedForUI.amountsWithToken,
    handleSuccessModalClose,
    keys.successModal.button,
    keys.successModal.deposit,
    keys.successModal.initializing,
    onDepositModalClick,
    status,
    t,
  ]);

  return (
    <>
      <AppModal
        open={successModalOpen}
        onClose={handleSuccessModalClose}
        maxWidth="xs"
        withoutCloseButton
        classes={{ paper: classes.modal }}
      >
        <Box className={classes.modalContent}>
          <IconButton
            aria-label="close"
            onClick={handleSuccessModalClose}
            color="inherit"
            className={classes.modalCloseBtn}
          >
            <Close sx={{ color: 'inherit' }} />
          </IconButton>

          <ImageX
            imgUrl={imgUrl}
            img2xUrl={imgUrl2x}
            className={classes.modalImage}
          />

          <Typography variant="h5">{t(keys.successModal.title)}</Typography>
          <Typography variant="body3" className={classes.modalHint}>
            {successModalHint}
          </Typography>

          {planPrice && !planPrice.isZero() && (
            <>
              <hr />
              <Box
                display="flex"
                gap={2}
                width="100%"
                justifyContent="space-between"
              >
                <Typography variant="subtitle2">
                  {t(keys.successModal.paidAmount)}
                </Typography>
                <Typography variant="subtitle2">
                  {tCommon('number.price', { value: planPrice })}
                </Typography>
              </Box>
            </>
          )}
          {successModalButton}
        </Box>
      </AppModal>
      <AppModal
        open={failedModalOpen}
        onClose={handleFailedModalClose}
        maxWidth="xs"
        title={t(keys.failedModal.title)}
        titleClassName={classes.failedModalTitle}
        titleCentered
        classes={{ paper: classes.modal }}
      >
        <Box className={classes.modalContent}>
          <Typography variant="body3" className={classes.modalHint}>
            {t(keys.failedModal.hint1)}
          </Typography>
          <Typography variant="body3" className={classes.modalHint}>
            {t(keys.failedModal.hint2)}
          </Typography>

          <LoadingButton
            size="large"
            onClick={onSubmitClick}
            loading={isExtendRollupLoading}
            fullWidth
            className={classes.modalBtn}
          >
            {t(keys.failedModal.button)}
          </LoadingButton>
        </Box>
      </AppModal>
    </>
  );
}
