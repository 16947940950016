import React, { JSX, useMemo } from 'react';
import { QueryLoadingAbsolute } from '@ankr.com/raas-ui';

import { useAuth } from '../../auth/hooks/useAuth';
import { AccountInfoForm } from '../../common/components/AccountInfoForm';
import { useGetAccountInfoQuery } from '../../common/components/AccountInfoForm/actions/getAccountInfo';

interface AccountInfoGuardProps {
  children: JSX.Element;
}

export function AccountInfoGuard({
  children,
}: AccountInfoGuardProps): JSX.Element {
  const { isSignedIn } = useAuth();

  const {
    data: accountInfoData,
    isLoading: isAccountInfoLoading,
    isSuccess: isAccountInfoSuccess,
  } = useGetAccountInfoQuery();

  const isVerified = useMemo(
    () => isSignedIn && isAccountInfoSuccess && accountInfoData?.isVerified,
    [accountInfoData?.isVerified, isAccountInfoSuccess, isSignedIn],
  );

  if (isAccountInfoLoading) {
    return <QueryLoadingAbsolute />;
  }

  return isVerified ? children : <AccountInfoForm showSubtitle />;
}
